import Vue from 'vue'
import App from '@/App.vue'
import router from "@/router/index.js"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.less'

Vue.use(ElementUI)

Vue.config.productionTip = false
console.log(process.env.VUE_APP_BASE_API)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
