import VueRouter from "vue-router"
import Vue from "vue"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "hash",
  routes: [
    { path: '/', component: () => import('@/views/home/index.vue'), meta: { title: '工业汇搜索页' } },
    {
      path: '/search-detail',
      component: () => import('@/views/search-detail/index.vue'),
      meta: { title: '工业汇搜索结果页' }
    },
    { path: '/login', component: () => import('@/views/login/index.vue'), meta: { title: '个人中心登录页' } },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
